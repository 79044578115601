// AdsLogo.tsx

import React, { useEffect, useRef } from 'react';

import { LogoProps } from './Props';

import '../../styles/size.css';
import '../../styles/color.css';


export default function AdsLogo({active_class} : LogoProps) {
    const ref = useRef<SVGPathElement>(null);

    useEffect(() => {
        if (ref.current && active_class) {
            ref.current.classList.add(active_class);
        }
    }, [active_class]);

    return(
        <svg version="1.1" viewBox="0 0 51 46" xmlns="http://www.w3.org/2000/svg" id="AdsLogo" className="Logo">
            <path d="M48 10.7573H33C32.337 10.7573 31.7011 11.0207 31.2322 11.4895C30.7634 11.9583 30.5 12.5942 
            30.5 13.2573V20.7573H25.5V3.25726C25.5 2.59422 25.2366 1.95834 24.7678 1.4895C24.2989 1.02066 23.663 
            0.757263 23 0.757263H3C2.33696 0.757263 1.70107 1.02066 1.23223 1.4895C0.763392 1.95834 0.5 2.59422 
            0.5 3.25726V43.2573C0.5 43.9203 0.763392 44.5562 1.23223 45.025C1.70107 45.4939 2.33696 45.7573 3 
            45.7573H48C48.663 45.7573 49.2989 45.4939 49.7678 45.025C50.2366 44.5562 50.5 43.9203 50.5 43.2573V13.2573C50.5 
            12.5942 50.2366 11.9583 49.7678 11.4895C49.2989 11.0207 48.663 10.7573 48 10.7573ZM15.5 
            8.25726H20.5V13.2573H15.5V8.25726ZM10.5 33.2573H5.5V28.2573H10.5V33.2573ZM10.5 23.2573H5.5V18.2573H10.5V23.2573ZM10.5 
            13.2573H5.5V8.25726H10.5V13.2573ZM20.5 33.2573H15.5V28.2573H20.5V33.2573ZM20.5 23.2573H15.5V18.2573H20.5V23.2573ZM43 
            33.2573H38V28.2573H43V33.2573ZM43 23.2573H38V18.2573H43V23.2573Z" ref={ref}/>
        </svg>
    )
}
