import React, { useState } from "react";

import "../../../styles/Graph.css";
import "../../../styles/icon.css";
import "../../../styles/size.css";

import Node from "./Node";
import Link from "./Link";
import Cross from "../Cross";
import { LangData } from "../Props";

import { useLangContext } from "../../../contexts/LangContext";
import PieGraph from "../PieGraph";


interface NodeData {
    title: LangData;
    subtitle?: LangData;
    description: LangData;
    advancement?: number;
}

function NodeDescription({title, subtitle, description, advancement} : NodeData) {
    const lang = useLangContext().lang;

    return ( 
        <div className='description-container'>
            <div className='description'>
                <h3>
                    {title[lang]}
                </h3>
                
                {subtitle && (
                    <h4>
                        {subtitle[lang]}
                    </h4>
                )}

                <span>
                    {description[lang]}
                </span>

                <PieGraph progress={advancement? advancement : 0}/>
            </div>

            <div className="plus-container">
                <Cross/>
            </div>
        </div>
    )
}

interface GraphData {
    title? : LangData;
    nodes: {
        [key: string]: NodeData;
    };
}

interface GraphProps {
    data : GraphData;
}

function Graph({ data }: GraphProps) {
    const lang = useLangContext().lang;

    const [ nodeSelected, setNodeSelected ] = useState<number | null>(null);

    function handleClick() {
        setNodeSelected(null);
    }

    const title = data.title ? data.title[lang] : undefined;
    const nodesData = data.nodes;

    const nodes = Object.values(nodesData).map((nodeData, index) => (
        <React.Fragment key={index}>
            <Link advancement={nodeData.advancement}/>
            <Node
                title={nodeData.title[lang]}
                subtitle={nodeData.subtitle ? nodeData.subtitle[lang] : undefined}
                index = {index}
                handleSelect={setNodeSelected}
            />
        </React.Fragment>
    ));

    return (
        <div className="graph-container" onClick={nodeSelected != null ? handleClick : undefined}>
            {title && 
                <h2>
                    {title}
                </h2>
            }
            
            <div className="graph">
                {nodes}
                <Link end={true}/>
            </div>

            {nodeSelected !== null &&
                NodeDescription(nodesData[Object.keys(nodesData)[nodeSelected]])
            }
        </div>
    );
}

export default Graph;
