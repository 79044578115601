import React from 'react';
import { Link } from 'react-router-dom';

import '../../styles/ContactButton.css';
import '../../styles/size.css';
import '../../styles/color.css';

import data from '../../data.json';

import { useLangContext } from '../../contexts/LangContext';
import { usePageContext } from '../../contexts/PageContext';

export default function ContactButton() {
    const lang = useLangContext().lang;
    const setPage = usePageContext().setPage;

    return(
        <Link to ='/About' className='contact-button' onClick={() => setPage('Contact')}>
            <div>
                <span>
                    {data["ContactButton"][lang as keyof typeof data["ContactButton"]]}
                </span>
            </div>
        </Link>
    )
}
