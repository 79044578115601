// About.tsx

import React, { useRef, useEffect, useState } from 'react';

import '../../styles/size.css';
import '../../styles/color.css';
import '../../styles/About.css';

import { useLangContext } from '../../contexts/LangContext';
import { usePageContext } from '../../contexts/PageContext';

import data from '../../data.json';

import Page from '../Utilities/Page';
import LinkedInLogo from "../Utilities/LinkedInLogo";

import AnimationWrapper from './AnimationWrapper';
import Banner from './Banner';


export default function About() {
    
    const { setPage, page } = usePageContext();
    const lang = useLangContext().lang;

    const contactRef = useRef<HTMLDivElement>(null);

    const [fast, setFast ] = useState(false);


    useEffect( () => {
        if (page === 'Contact') {
            setFast(true);
        } else {
            setFast(false);
        }
    }, [ page, setPage ]);


    useEffect( () => {
        if (contactRef.current && page === 'Contact') {
            contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'});
        }
    }, [ page ]);

    return (
        <Page
            id="About"
            title={data.About.title[lang as keyof typeof data.About.title]}
            canonical="htttps://all-enterprises.com/About"
            appleTouchIcon="/ALL_favicon/apple-touch-icon.png"
            icon_32="/ALL_favicon/favicon-32x32.png"
            icon_16="/ALL_favicon/favicon-16x16.png"
            favicon="/ALL_favicon/favicon.ico"
            manifest="/ALL_favicon/site.webmanifest"
        >
    
            <Banner/>
            
            <div className='description'>
                <AnimationWrapper fast={fast} id="Values" className="widget">
                    <div id="ValuesContainer">
                        <div className="left">
                            <div className="text-block-description">
                                {data.About.widgets.values.description[lang as keyof typeof data.About.widgets.values.description]}
                            </div>
                        </div>

                        {/* <div className="right">
                            <img src="/assets/Robot.png" alt="Robot Illustration"/>
                        </div> */}
                    </div>
                </AnimationWrapper>

                <AnimationWrapper fast={fast} id='Contact' className='widget' previousRef={contactRef}>
                    <div>
                        <h2 id="contact-title">
                            {data.About.widgets.contact.title[lang as keyof typeof data.About.widgets.contact.title]}
                        </h2>
                        <div className="text-block-description">
                            <div id='contactContainer'>
                                <a href={"mailto:" + data.About.widgets.contact.email + "?subject=Contact"} id="Email" >
                                    {data.About.widgets.contact.email}
                                </a>
                            </div>
                        </div>
                    </div>
                </AnimationWrapper>

                <AnimationWrapper fast={fast} id='SocialMedia' className='widget'>
                    <button id="LinkedInButton" onClick={() => window.open('https://www.linkedin.com/company/allenterprise/')}>
                        <LinkedInLogo/>
                    </button>
                </AnimationWrapper>

            </div>

            <div ref={contactRef}/>
        </Page>
    )
}
