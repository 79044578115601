import React, { useEffect, useState } from "react";


import "../../styles/icon.css";
import "../../styles/color.css";
import "../../styles/size.css";

interface PieGraphProps {
    progress: number;
}

export default function PieGraph({ progress }: PieGraphProps) {
    const radius = 90;

    const perimeter = 2 * Math.PI * radius;

    const [offset, setOffset] = useState(perimeter * (1 - progress));

    useEffect(() => {
        setOffset(perimeter * (1 - progress));
    }, [progress, perimeter]);

    return (
        <svg viewBox="-10 -10 230 230" version="1.1" xmlns="http://www.w3.org/2000/svg" className="pie-graph" style={{ transform: 'rotate(-90deg)' }}>
            <circle r={radius} cx="100" cy="100" className="background-donut"/>
            <circle r={radius} cx="100" cy="100" stroke="green" strokeLinecap="round" className="foreground-donut" strokeDasharray={perimeter} strokeDashoffset={offset}/>
            
            <g transform="rotate(90, 100, 100)">
                <text x="100" y="100" textAnchor="middle" dy="5" dominantBaseline="middle" fontSize="55px" fontWeight="semi-bold">{progress * 100}%</text>
            </g>
        </svg>
    );
}
