// addEmail.tsx

import axios from 'axios';


const postEmail = async (email : string) => {
    const url = "/email";
    const data = {email};
    const response = await axios.post(url, data);
    return response;
}

export { postEmail };
