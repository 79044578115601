// TuneDescription.tsx

import React from 'react'

import '../../styles/Tune.css'
import '../../styles/size.css'

import { useLangContext } from '../../contexts/LangContext'

import TuneLogo from '../Utilities/TuneLogo'
import { LangData } from '../Utilities/Props'


interface TuneDescriptionProps {
    value: LangData;
    description: LangData[];
}

export default function TuneDescription({ value, description } : TuneDescriptionProps) {
    const lang = useLangContext().lang;

    return (
        <div id="TuneDescription">
            <TuneLogo/>

            <div className="description">
                
                <h1>
                    Tune
                </h1>

                <h2>
                    {value[lang]}
                </h2>
                
                {description.map((P, index) => (
                    <p className='description' key={index}>{P[lang as keyof typeof P]}</p>
                ))}
            </div>
        </div>
    );
}