// LangContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';


function getInitialLang() {
    return navigator.language == "fr-FR" ? 'fr' : 'en';
}

// Définissez un type pour votre contexte si nécessaire
interface LangContextType {
    lang: string;
    setLang: (newLang: string) => void;
}

// Créez votre contexte avec une valeur par défaut
const LangContext = createContext<LangContextType>({
    lang: getInitialLang(),
    setLang: () => {},
});


// Créez un hook personnalisé pour utiliser le contexte
const useLangContext = () => {
    const context = useContext(LangContext);

    if (!context) {
        throw new Error('useLangContext must be used within a LangContextProvider');
    }
    return context;
};


// Créez un composant de fournisseur pour votre contexte
interface LangContextProviderProps {
    children: ReactNode;
}


const LangContextProvider: React.FC<LangContextProviderProps> = ({ children }) => {
    // Définissez l'état ou les fonctions que vous souhaitez partager
    const [lang, setLang] = useState<string>(getInitialLang());

    // Passez les valeurs à votre contexte
    const contextValue: LangContextType = {
        lang,
        setLang,
    };

    return <LangContext.Provider value={contextValue}>{children}</LangContext.Provider>;
};


export { useLangContext, LangContextProvider };
