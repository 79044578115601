import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { usePageContext } from '../../contexts/PageContext';

import "../../styles/NavBar.css";
import { LogoProps } from '../Utilities/Props';
import { useRootContext } from '../../contexts/RootContext';

interface ButtonProps {
    page?: string;
    to?: string;
    active_class?: string;
    onClick?: () => void;
    label?: string;
    
    Logo: React.FC<LogoProps>;
}

const Button: React.FC<ButtonProps> = ({page, Logo, to, active_class, onClick, label}) => {
    const currentPage = usePageContext().page;
    const ref = useRef<HTMLAnchorElement>(null);
    const pageRef = useRootContext().root;

    function resetScroll() {
        if (pageRef) {
            pageRef.scrollTo(0, 0);
        }
    }


    function handleClick() {
        if (to) {
            resetScroll();
        }

        if (onClick) {
            onClick();
        }
    }


    useEffect(() => {
        if (active_class && ref.current && page) {
            if (currentPage === page) {
                ref.current.classList.add(active_class);
            } else {
                ref.current.classList.remove(active_class);
            }
        }
    }, [ currentPage ]);


    return(
        <Link to={to ? to : currentPage} className='nav_button' onClick={handleClick} ref={ref}>
            <div className='logo'>
                <Logo/>
            </div>
            <span>
                {label ? label : page}
            </span>
        </Link>
    )
};

export default Button;
