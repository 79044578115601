// Footer.tsx

import React from 'react';

import '../../styles/Footer.css'
import '../../styles/size.css'
import '../../styles/color.css'

import { useLangContext } from '../../contexts/LangContext';

import allData from '../../data.json'

import Section from "./Section";
import Page from "./Page";

import CGU_FR from '../../documents/CGU_FR.md';
import ML_FR from '../../documents/ML_FR.md';
import CGU_EN from '../../documents/CGU_EN.md';
import ML_EN from '../../documents/ML_EN.md';


export default function Footer() {
    const { lang, setLang } = useLangContext();
    const langData = allData['lang'];
    const data = allData['Footer'];

    return (
        <>
            <div id='sections'>
                <Section title={"Légal"}>
                    <Page content={lang === 'fr' ? CGU_FR : CGU_EN} name={data['CGU'][lang as keyof typeof data['CGU']]}/>
                    <Page content={lang === 'fr' ? ML_FR : ML_EN} name={data['LegalNotice'][lang as keyof typeof data['LegalNotice']]}/>
                </Section>
                
                <Section title={"Langues"}>
                    <Page name={langData['en'][lang as keyof typeof langData]} onClick={() => setLang('en')}/>
                    <Page name={langData['fr'][lang as keyof typeof langData]} onClick={() => setLang('fr')}/>
                </Section>
            </div>

            <div id="copyright">
                {data.copyright[lang as keyof typeof data.copyright]}
            </div>
        </>
    );
}
