import React from 'react';

import { StyleProps } from './Props';


export default function Arrow({className, id} : StyleProps) {
    return (
        <svg viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} id={id}>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.25 4.75723C0.25 4.55832 0.329018 4.36755 
                0.46967 4.2269C0.610322 4.08625 0.801088 4.00723 1 4.00723H11.19L8.47 1.28723C8.33752 
                1.14506 8.2654 0.95701 8.26882 0.762709C8.27225 0.568408 8.35097 0.383023 8.48838 
                0.24561C8.62579 0.108197 8.81118 0.029485 9.00548 0.0260568C9.19978 0.0226286 9.38782 
                0.0947517 9.53 0.227232L13.53 4.22723C13.6705 4.36786 13.7493 4.55848 13.7493 4.75723C13.7493 
                4.95598 13.6705 5.14661 13.53 5.28723L9.53 9.28723C9.46134 9.36092 9.37854 9.42002 9.28654 
                9.46101C9.19454 9.502 9.09522 9.52405 8.99452 9.52582C8.89382 9.5276 8.79379 9.50908 8.7004 
                9.47135C8.60701 9.43363 8.52218 9.37749 8.45096 9.30627C8.37974 9.23505 8.3236 9.15022 8.28588 
                9.05683C8.24816 8.96344 8.22963 8.86341 8.23141 8.76271C8.23318 8.66201 8.25523 8.56269 8.29622 
                8.47069C8.33721 8.37869 8.39631 8.29589 8.47 8.22723L11.19 5.50723H1C0.801088 5.50723 0.610322 
                5.42821 0.46967 5.28756C0.329018 5.14691 0.25 4.95614 0.25 4.75723Z" fill="#FFFFFF"/>
        </svg>
    )
}
