import React from "react";

import Plus from "../Plus";

import "../../../styles/Graph.css";
import "../../../styles/icon.css";

interface NodeProps {
    title: string;
    subtitle?: string;
    index: number;
    handleSelect: (index: number) => void;
}

function Node({title, subtitle, index, handleSelect}: NodeProps) {

    function handleClick() {
        console.log("Node clicked");
        handleSelect(index);
    }

    return (
        <div className="node" onClick={handleClick}>
            <h3>{title}</h3>
            
            {subtitle &&
                <h4>{subtitle}</h4>
            }
            
            <div className="plus-container">
                <Plus/>
            </div>
        </div>
    );
}

export default Node;
