import React from "react";
import { LangData } from "../Utilities/Props";
import { useLangContext } from "../../contexts/LangContext";

// interface ParticuleProps {
//     index : number;
// }

// function Particule({index} : ParticuleProps) {
//     const generatePath = () => {
//         const points = [];
//         const numPoints = Math.floor(Math.random() * 100 + 10);
//         const amplitude = Math.floor(Math.random() * 15);
//         const frequency = (Math.random() < 0.5 ? -1 : 1) * 0.001;
    
//         for (let i = 0; i <= numPoints; i++) {
//             const x = (i / numPoints) * 100;
//             const y = 50 + amplitude * Math.sin(frequency * i * Math.PI) * i;
//             points.push({ x, y });
//         }
    
//         // Convertir les points en une chaîne de commande SVG
//         const pathData = points.map((point, index) => {
//             return index === 0 ? `M${point.x},${point.y}` : `L${point.x},${point.y}`;
//         }).join(" ");
    
//         return pathData;
//     };

//     const getRandomColor = () => {
//         const letters = "0123456789ABCDEF";
//         let color = "#";
//         for (let i = 0; i < 6; i++) {
//             color += letters[Math.floor(Math.random() * 16)];
//         }
//         return color;
//     }

//     return (
//         <svg
//             className="sphere"
//             style={{
//                 position: "absolute",
//                 top: `0%`,
//                 left: `0%`,
//                 height: "100%",
//                 width: "100%",
//                 // transition: "top 1s ease, left 1s ease",
//                 willChange: "transform",
//             }}
//             viewBox="0 0 100 100"
//         >
//             <path id={`motionPath${index}`} d={generatePath()} fill="transparent" stroke={"rgba(255, 255, 255, 0.03)"} />
//             <circle cx="0" cy="0" r="2" fill={getRandomColor()}>
//                 <animateMotion repeatCount="indefinite" dur={`${Math.floor(Math.random() * 20 + 10)}s`}>
//                     <mpath href={`#motionPath${index}`} />
//                 </animateMotion>
//             </circle>
//         </svg>
//     )
// }

interface BannerProps {
    data: LangData;
}

export default function Banner({data} : BannerProps) {

    const lang = useLangContext().lang;

    // const [particles, setParticles] = useState<JSX.Element[]>([]);

    // useEffect(() => {
    //     const generatedParticles = Array.from({ length: 100 }, (_, i) => <Particule key={i} index={i}/>);
    //     setParticles(generatedParticles);
    // }, []);

    return (
        <div className="banner">
            <div className="title-container-big">
                <div className="title-container-small">
                    <h1>Spark</h1>
                    <h2>{data[lang]}</h2>
                </div>
            </div>
        </div>
    );
}
