// ScrollContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';

// Définissez un type pour votre contexte si nécessaire
interface ScrollContextType {
    scroll: number | null;
    setScroll: (newValue: number | null) => void;
}


// Créez votre contexte avec une valeur par défaut
const ScrollContext = createContext<ScrollContextType>({
    scroll: null,
    setScroll: () => {},
});


// Créez un hook personnalisé pour utiliser le contexte
const useScrollContext = () => {
    const context = useContext(ScrollContext);
    if (!context) {
        throw new Error('useScrollContext must be used within a ScrollContextProvider');
    }
    return context;
};


// Créez un composant de fournisseur pour votre contexte
interface ScrollContextProviderProps {
    children: ReactNode;
}


const ScrollContextProvider: React.FC<ScrollContextProviderProps> = ({ children }) => {
    // Définissez l'état ou les fonctions que vous souhaitez partager
    const [scroll, setScroll] = useState<number | null>(null);

    // Passez les valeurs à votre contexte
    const contextValue: ScrollContextType = {
        scroll,
        setScroll,
    };

    return <ScrollContext.Provider value={contextValue}>{children}</ScrollContext.Provider>;
};


export { useScrollContext, ScrollContextProvider };
