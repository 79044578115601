//LangLogo.tsx


import React from 'react'

import { useLangContext } from '../../contexts/LangContext';


export default function LangLogo() {

    const flag_data = {
        'flag' : {
            'fr' : '/assets/French.svg',
            'en' : '/assets/English.svg'
        },
        'alt' : {
            'fr' : 'Drapeau Français',
            'en' : 'English Flag'
        }
    };

    const lang = useLangContext().lang;

    return (
        <img src={flag_data.flag[lang as keyof typeof flag_data['flag']]} 
            alt={flag_data.alt[lang as keyof typeof flag_data['alt']]} id='flag' className='Logo'/>
    )
}
