import React from 'react';

import '../../styles/size.css';
import '../../styles/color.css';
import '../../styles/About.css';

import { useLangContext } from '../../contexts/LangContext';

import data from '../../data.json';

function Underlined(key : number, string : string | boolean) {
    return(
        <span key={key}>{string}</span>
    );
}

export default function Banner() {
    const lang = useLangContext().lang;

    let current;
    let second_line = false;

    for (let i = 0; i < data.About.banner[lang as keyof typeof data.About.banner].length; i++) {
        const el = data.About.banner[lang as keyof typeof data.About.banner][i];
        
        if (el[2] && !second_line) {
            second_line = true;
            current = [current, <br key={i}/>];
        }

        if (el[1]) {
            current = [current, Underlined(i, el[0])];
        } else {
            current = [current, el[0]];
        }
    }

    return(
        <div className='banner'>
            <h1>
                {current}
            </h1>
        </div>
    );
}
