// LangContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';


// Définissez un type pour votre contexte si nécessaire
interface PageContextType {
    page: string;
    setPage: (newPage: string) => void;
}


// Créez votre contexte avec une valeur par défaut
const PageContext = createContext<PageContextType>({
    page: 'Tune',
    setPage: () => {},
});


// Créez un hook personnalisé pour utiliser le contexte
const usePageContext = () => {
    const context = useContext(PageContext);
    if (!context) {
        throw new Error('usePageContext must be used within a PageContextProvider');
    }
    return context;
};


// Créez un composant de fournisseur pour votre contexte
interface PageContextProviderProps {
    children: ReactNode;
}


const PageContextProvider: React.FC<PageContextProviderProps> = ({ children }) => {
    // Définissez l'état ou les fonctions que vous souhaitez partager
    const [page, setPage] = useState<string>('Tune');

    // Passez les valeurs à votre contexte
    const contextValue: PageContextType = {
        page,
        setPage,
    };

    return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
};


export { usePageContext, PageContextProvider };
