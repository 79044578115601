// TuneLogo.tsx

import React, { useRef, useEffect } from 'react'

import { LogoProps } from './Props'

import '../../styles/size.css'
import '../../styles/color.css'


export default function TuneLogo({active_class} : LogoProps) {
    const ref = useRef<SVGGElement>(null);

    useEffect(() => {
        if (ref.current && active_class) {
            ref.current.classList.add(active_class);
        }
    });

    return(
        <svg version="1.1" viewBox="-10 0 152 166" xmlns="http://www.w3.org/2000/svg" id="TuneLogo" className="Logo">
            <g transform="translate(-28.76 -43.965)" ref={ref}>
                <path d="m107 114 H 170 L 118 43.965 V 60 L 107 43.965 V 0z"/>
                <path d="m76.023 203.87c-11.267-1.2761-21.817-6.5351-30.481-15.195-9.5187-9.5142-15.094-21.281-16.573-34.974-0.4086-3.7845-0.19118-12.303 
                0.40375-15.818 3.4186-20.2 16.297-38.435 34.953-49.491 9.1121-5.4 19.164-8.7449 29.882-9.9433 3.0943-0.34599 8.3827-0.58634 10.345-0.47018l0.60135 
                0.03559v30.957l-4.6104-0.14102c-5.3473-0.16357-9.3402 0.26084-14.151 1.5041-16.523 4.2703-29.67 17.672-33.138 33.778-0.84199 3.9106-0.84199 11.157 
                0 15.068 1.5773 7.3258 4.6 12.929 9.7918 18.152 3.7373 3.7594 7.5743 6.2274 12.346 7.9414 6.6585 2.3915 15.435 1.9198 21.48-1.1545 3.6221-1.8421 
                7.5035-5.3 9.7493-8.6855 2.5959-3.9132 3.6328-7.2611 3.6289-11.716-5e-3 -5.2973-1.6092-9.2559-5.225-12.891-3.12-3.1364-6.4712-4.6492-10.315-4.6561-3.1106-6e-3 
                -5.8746 1.0914-8.1344 3.2287-2.5992 2.4582-3.6004 5.6718-2.6793 8.6001 1.087 3.456 4.8909 5.3457 7.0698 3.5123 1.1665-0.98154 1.0221-0.24677-0.18964 0.96496-1.2904 
                1.2904-2.7454 1.716-4.5153 1.3208-2.6377-0.58902-4.9106-2.5384-6.1838-5.3035-0.99703-2.1654-1.1347-5.6314-0.31983-8.0502 2.9627-8.7937 12.795-13.584 22.466-10.946 
                2.8978 0.7906 6.4837 2.6548 9.0382 4.6986 11.28 9.025 13.84 25.588 6.0623 39.223-2.7053 4.7426-7.2534 9.7517-11.72 12.909-2.679 1.8932-7.1506 4.2403-10.073 
                5.2871-5.9031 2.1146-13.247 2.9627-19.511 2.2532z"/>
            </g>
        </svg>
    )
}
