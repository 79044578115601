// App.tsx

import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import '../styles/size.css';
import '../styles/color.css';
import '../styles/App.css';

import { useLangContext, LangContextProvider } from '../contexts/LangContext';
import {  PageContextProvider } from '../contexts/PageContext';
import { ScrollContextProvider, useScrollContext} from '../contexts/ScrollContext';

import Tune from './Tune/Tune';
import About from './About/About';
import Ads from './Ads/Ads';
import NavBar from './NavBar/NavBar';
import Footer from './Footer/Footer';
import Spark from './Spark/Spark';
import { useRootContext } from '../contexts/RootContext';


function Content() {
    const lang = useLangContext().lang;
    const setScroll  = useScrollContext().setScroll;
    const rootElement = useRootContext().root;

    useEffect( () => {
        function handleScroll() {
            if (!rootElement) {
                return;
            }
            setScroll(rootElement.scrollTop / (rootElement.scrollHeight - rootElement.clientHeight));
        }

        if (!rootElement) {
            return;
        }
        rootElement.addEventListener('scroll', handleScroll, {capture : true});

        return () => {
            rootElement.removeEventListener('scroll', handleScroll, {capture : true});
        }
    });

    return (
        <>
            <Helmet>
                <html lang={lang}/>
                <meta name="theme-color" content="#000000"/>
            </Helmet>

            <header>
                <NavBar/>
            </header>

            <div className="page">
                <Routes>
                    <Route path="/" element={<Tune/>}/>
                    <Route path="/Tune" element={<Tune/>}/>
                    <Route path="/About" element={<About/>}/>
                    <Route path="/Ads" element={<Ads/>}/>
                    <Route path="/Spark" element={<Spark/>}/>
                </Routes>
            </div>

            <footer>
                <Footer/>
            </footer>
        </>
    )
}


export default function App() {
    return (
        <BrowserRouter>
            <LangContextProvider>
                <PageContextProvider>
                    <ScrollContextProvider>
                        <Content/>
                    </ScrollContextProvider>
                </PageContextProvider>
            </LangContextProvider>
        </BrowserRouter>
    );
}
