// NextClients.tsx

import React from 'react';

import '../../styles/Ads.css';
import '../../styles/size.css';
import '../../styles/color.css';

import PubPresentation from './PubPresentation';
import ALLLogo from '../Utilities/ALLLogo';
import ContactButton from '../Utilities/ContactButton';
import { BlocProps, LangData } from '../Utilities/Props';

import { useLangContext } from '../../contexts/LangContext';


interface NextClientProps {
    ads : LangData;
    data : BlocProps;
}


export default function NextClient({ ads, data } : NextClientProps) {

    const lang = useLangContext().lang;

    return (
        <section id="NextClients">
            <div className='container'>
                <div className="description">
                    <div id="AdsHead">
                        <ALLLogo/>
                        <h1>
                            {ads[lang]}
                        </h1>
                    </div>

                    <h2>
                        {data.title[lang]}
                    </h2>
                    
                    <p>
                        {data.description[lang]}
                    </p>
                </div>
                
                <ContactButton/>
            </div>

            <PubPresentation/>
        </section>
    );
}