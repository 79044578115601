// readMarkDown.tsx

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';


interface ReadMarkdownProps {
    content: string;
}

export default function ReadMarkdown({ content }: ReadMarkdownProps) {
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        fetch(content)
            .then((res) => res.text())
            .then((text) => setMarkdown(text.replace(/\n/g, "\n\n")));
    }, [ content ]);

    return (
        <ReactMarkdown>{markdown}</ReactMarkdown>
    );
}
