// Page.tsx

import React, { useState } from 'react';

import '../../styles/Footer.css'
import '../../styles/size.css'
import '../../styles/color.css'

import Cross from '../Utilities/Cross';
import ReadMarkdown from '../../helpers/readMarkDown';


interface PageProps {
    onClick?: () => void;
    name?: string;
    content?: string;
}


export default function Page({name, onClick, content} : PageProps) {
    const [isAnimated, setIsAnimated] = useState(false);
    const [selected, setSelected] = useState(false);

    function handleClick() {
        if(onClick) {
            onClick();
        } else {
            if (!selected) {
                setSelected(true);
            } else {
                setIsAnimated(true);
                setTimeout(() => {
                    setSelected(false);
                    setIsAnimated(false);
                }, 480);
            }
        }
    }

    return(
        <>
            <div className="page-footer" onClick={handleClick}>
                <h3>{name}</h3>
            </div>

            {selected &&
                (<div className={`page-footer-developed-container ${isAnimated ? 'hide' : '' }`} onClick={handleClick}>
                    <div className={`page-footer-developed ${isAnimated ? 'hide' : '' }`}>
                        <div className='plus-container cross-container'>
                            <Cross/>
                        </div>
                        
                        {name && !content && (
                            <>
                                <h3>{name}</h3>
                                <p>Texte</p>
                            </>
                        )}

                        {content &&
                            <ReadMarkdown content={content}/>
                        }
                    </div>
                </div>)
            }
        </>
    )
}
