// RootContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';

// Définissez un type pour votre contexte si nécessaire
interface RootContextType {
    root: HTMLElement | null;
    setRoot: (newValue: HTMLElement | null) => void;
}


// Créez votre contexte avec une valeur par défaut
const RootContext = createContext<RootContextType>({
    root: null,
    setRoot: () => {},
});


// Créez un hook personnalisé pour utiliser le contexte
const useRootContext = () => {
    const context = useContext(RootContext);
    if (!context) {
        throw new Error('useRootContext must be used within a RootContextProvider');
    }
    return context;
};


// Créez un composant de fournisseur pour votre contexte
interface RootContextProviderProps {
    children: ReactNode;
    rootElement?: HTMLElement;
}


const RootContextProvider: React.FC<RootContextProviderProps> = ({ children, rootElement }) => {
    // Définissez l'état ou les fonctions que vous souhaitez partager
    const [root, setRoot] = useState<HTMLElement | null>(rootElement ? rootElement : null);

    // Passez les valeurs à votre contexte
    const contextValue: RootContextType = {
        root,
        setRoot,
    };

    return <RootContext.Provider value={contextValue}>{children}</RootContext.Provider>;
};


export { useRootContext, RootContextProvider };
