import React from 'react';

import { useLangContext } from '../../contexts/LangContext';

import Button from './Button';
import ALLLogo from '../Utilities/ALLLogo';
import TuneLogo from '../Utilities/TuneLogo';
import AdsLogo from '../Utilities/AdsLogo';
import LangLogo from '../Utilities/LangLogo';

import '../../styles/NavBar.css';
import '../../styles/color.css';
import '../../styles/size.css';

import data from '../../data.json';


export default function NavBar() {
    const { lang, setLang } = useLangContext();

    const changeLang = () => {
        setLang(lang === 'en'? 'fr' : 'en');
    };

    return (
        <nav>
            <Button to='/About' page='About' Logo={ALLLogo} active_class='greyed' label={data['About'].title[lang as keyof typeof data['Language']]}/>
            
            <Button to='/' page='Tune' Logo={TuneLogo} active_class='purpled'/>

            <Button to='/Ads' page='Ads' Logo={AdsLogo} active_class='reded'/>

            <Button Logo={LangLogo} onClick={changeLang} label={data["Language"][lang as keyof typeof data['Language']]}/>
        </nav>
    );
}
