// ALLLogo.tsx

import React from 'react'

import '../../styles/ALLButton.css'
import '../../styles/size.css'
import '../../styles/color.css'

export default function ALLLogo() {
    return(
        <svg viewBox="0 0 110 50" xmlns="http://www.w3.org/2000/svg" id="ALL_logo" className="Logo">
            <polygon points="10 40, 40 40, 25 10" id="trig_iso"/>
            <polygon points="50 40, 70 40, 50 10" className="trig_rect"/>
            <polygon points="80 40, 100 40, 80 10" className="trig_rect"/>
        </svg>
    )
}
