// Button.tsx

import React from 'react';
import { Link } from 'react-router-dom';

import { LangData } from '../Utilities/Props';
import { useLangContext } from '../../contexts/LangContext';


interface ButtonProps {
    id: string;
    data: LangData;
    to?: string;
}

export default function Button({id, data, to} : ButtonProps) {
    const lang = useLangContext().lang;

    return (
        <Link to={to ? to : ""}>
            <button id={id} className='tune-button'>
                {data[lang]}
            </button>
        </Link>
    )
}
