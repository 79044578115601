// JoinUsLogo.tsx

import React from 'react'

import '../../styles/ALLButton.css'
import '../../styles/size.css'
import '../../styles/color.css'

export default function JoinUsLogo() {
    return(
        // <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1451.000000 1189.000000"
        // preserveAspectRatio="xMidYMid meet" id="JoinUsLogo">
        //     <g transform="translate(0.000000,1189.000000) scale(0.100000,-0.100000)">
        //         <path d="M6985 11808 c-322 -39 -625 -188 -855 -418 -392 -394 -528 -967 -355
        //         -1494 75 -229 197 -419 382 -597 214 -205 466 -332 768 -385 105 -19 414 -26
        //         553 -13 335 32 635 168 875 398 288 277 438 609 454 1006 10 245 -37 466 -146
        //         690 -75 153 -161 274 -281 395 -204 205 -452 337 -750 401 -90 19 -138 23
        //         -340 24 -129 1 -266 -2 -305 -7z"/>
        //         <path d="M5505 11072 c-232 -97 -414 -270 -520 -495 -158 -332 -129 -742 76
        //         -1073 65 -105 242 -289 339 -353 185 -121 412 -191 615 -191 89 0 135 24 119
        //         63 -2 6 -48 35 -102 62 -329 169 -569 637 -589 1146 -12 305 47 550 178 742
        //         29 42 42 73 43 97 1 33 -1 35 -34 37 -22 2 -67 -11 -125 -35z"/>
        //         <path d="M8844 11095 c-10 -25 4 -63 47 -125 129 -189 188 -435 176 -739 -16
        //         -404 -160 -766 -402 -1006 -73 -73 -108 -99 -187 -140 -54 -27 -100 -56 -102
        //         -62 -41 -104 338 -74 588 47 130 63 204 117 321 235 198 200 301 415 336 696
        //         13 108 -1 287 -30 403 -78 298 -304 554 -595 671 -92 38 -143 44 -152 20z"/>
        //         <path d="M1049 9979 c-150 -9 -228 -27 -318 -74 -140 -74 -331 -273 -424 -445
        //         -81 -148 -167 -465 -197 -720 -31 -264 -39 -419 -46 -860 -4 -265 -2 -480 5
        //         -595 10 -167 26 -480 51 -1020 5 -121 12 -247 15 -280 40 -463 54 -575 100
        //         -790 18 -88 43 -225 55 -305 27 -181 56 -272 120 -380 61 -102 93 -136 228
        //         -245 275 -221 445 -308 1192 -609 140 -56 291 -123 335 -148 261 -148 488
        //         -286 545 -329 215 -166 558 -494 671 -642 32 -43 79 -120 103 -170 63 -134
        //         168 -404 211 -542 21 -66 61 -196 91 -290 108 -344 196 -675 243 -912 42 -208
        //         61 -255 133 -326 102 -100 259 -163 553 -223 237 -49 902 -3 1180 81 62 19
        //         162 46 220 60 150 35 395 135 534 218 168 99 175 106 222 205 41 85 42 90 56
        //         262 33 399 27 1058 -12 1500 -53 584 -90 813 -175 1096 -54 181 -70 219 -142
        //         343 -56 97 -79 124 -272 317 -151 150 -232 223 -283 255 -39 24 -159 103 -265
        //         175 -433 294 -604 387 -1513 820 -666 317 -1080 484 -1411 568 -144 37 -340
        //         13 -493 -59 -40 -19 -81 -35 -90 -35 -27 0 -109 56 -163 112 -152 157 -182
        //         272 -198 773 -6 171 -14 362 -20 425 -5 63 -12 198 -15 300 -3 102 -9 196 -12
        //         210 -4 14 -7 381 -8 815 l-1 790 -27 120 c-59 251 -173 409 -364 504 -121 61
        //         -163 66 -414 50z"/>
        //         <path d="M13162 9974 c-70 -18 -165 -66 -230 -115 -117 -88 -203 -240 -249
        //         -434 l-27 -120 -1 -790 c-1 -434 -4 -801 -8 -815 -3 -14 -9 -108 -12 -210 -3
        //         -102 -10 -237 -15 -300 -6 -63 -14 -254 -20 -425 -16 -501 -46 -616 -198 -773
        //         -54 -56 -136 -112 -163 -112 -9 0 -50 16 -90 35 -153 72 -349 96 -493 59 -331
        //         -84 -745 -251 -1411 -568 -909 -433 -1080 -526 -1513 -820 -106 -72 -226 -151
        //         -265 -175 -51 -32 -132 -105 -283 -255 -193 -193 -216 -220 -272 -317 -72
        //         -124 -88 -162 -142 -343 -85 -283 -122 -512 -175 -1096 -39 -442 -45 -1101
        //         -12 -1500 14 -172 15 -177 56 -262 47 -99 54 -106 222 -205 139 -83 384 -183
        //         534 -218 58 -14 158 -41 220 -60 278 -84 943 -130 1180 -81 294 60 451 123
        //         553 223 72 71 91 118 133 326 47 237 135 568 243 912 30 94 70 224 91 290 43
        //         138 148 408 211 542 24 50 71 127 103 170 113 148 456 476 671 642 57 43 284
        //         181 545 329 44 25 195 92 335 148 747 301 917 388 1192 609 135 109 167 143
        //         228 245 64 108 93 199 120 380 12 80 37 217 55 305 46 215 60 327 100 790 3
        //         33 10 159 15 280 25 540 41 853 51 1020 7 115 9 330 5 595 -7 441 -15 596 -46
        //         860 -30 255 -116 572 -197 720 -93 171 -284 371 -424 445 -94 49 -162 64 -344
        //         75 -198 12 -208 12 -273 -6z"/>
        //         <path d="M6100 8698 c-354 -76 -836 -300 -1295 -603 -925 -609 -1624 -1422
        //         -1625 -1888 0 -99 -4 -96 253 -193 95 -35 188 -71 207 -79 19 -8 250 -96 514
        //         -196 580 -220 551 -218 526 -25 -16 122 -16 363 0 506 34 311 131 618 301 960
        //         252 506 629 965 1122 1367 74 61 127 112 127 122 0 40 -38 49 -130 29z"/>
        //         <path d="M8293 8699 c-27 -28 -10 -50 115 -152 492 -402 869 -861 1121 -1367
        //         170 -342 267 -649 301 -960 16 -143 16 -384 0 -506 -14 -113 -12 -129 24 -145
        //         21 -10 86 12 502 170 264 100 495 188 514 196 19 8 112 44 207 79 257 97 253
        //         94 253 193 -1 567 -1014 1605 -2135 2189 -365 190 -853 354 -902 303z"/>
        //         <path d="M7140 8670 c-392 -41 -851 -298 -1246 -697 -512 -519 -873 -1200
        //         -994 -1879 -26 -143 -40 -546 -22 -581 8 -14 42 -32 104 -54 105 -37 326 -148
        //         466 -233 541 -328 1070 -805 1352 -1220 39 -58 86 -116 102 -128 17 -12 63
        //         -27 102 -34 136 -25 520 -13 581 18 30 15 71 63 136 160 229 340 697 779 1156
        //         1086 226 151 491 294 651 351 62 22 96 40 104 54 18 35 4 438 -22 581 -57 320
        //         -162 632 -317 936 -516 1014 -1438 1716 -2153 1640z"/>
        //     </g>
        // </svg>


        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" id="JoinUsLogo">
            <path d="M13.637 2.36312L15.313 2.69812C15.403 2.71612 15.477 2.78212 15.503 2.87112C15.5161 2.91435 15.5173 
                2.96034 15.5064 3.00418C15.4955 3.04803 15.4729 3.08809 15.441 3.12012L14.068 4.49412C13.9035 4.65772 
                13.681 4.74972 13.449 4.75012H12.31L9.45 7.61112C9.50456 7.81714 9.5146 8.03243 9.47943 8.24263C9.44427 
                8.45283 9.36472 8.65313 9.24607 8.83018C9.12743 9.00723 8.97242 9.15696 8.79137 9.2694C8.61032 9.38184 
                8.40739 9.45441 8.19609 9.48227C7.98479 9.51013 7.76999 9.49265 7.56598 9.43098C7.36197 9.36932 7.17345 
                9.26489 7.01296 9.12465C6.85248 8.98441 6.72372 8.81158 6.63527 8.61767C6.54682 8.42377 6.50071 8.21324 
                6.5 8.00012C6.49995 7.76994 6.55287 7.54284 6.65467 7.3364C6.75647 7.12996 6.90442 6.94972 7.08705 
                6.80962C7.26968 6.66953 7.48211 6.57335 7.70787 6.52852C7.93364 6.4837 8.1667 6.49143 8.389 6.55112L11.25 
                3.68912V2.55212C11.25 2.32012 11.342 2.09712 11.506 1.93312L12.88 0.559119C12.912 0.527248 12.9521 0.504652 
                12.9959 0.493735C13.0398 0.482818 13.0858 0.483988 13.129 0.497119C13.218 0.523119 13.284 0.597119 13.302 
                0.687119L13.637 2.36312Z"/>
            <path d="M1.99994 8.00004C2.00097 8.85801 2.18599 9.70577 2.54251 10.4861C2.89904 11.2665 3.41878 11.9614 4.06667 
                12.5238C4.71457 13.0862 5.47555 13.5032 6.29828 13.7465C7.12101 13.9898 7.98636 14.0539 8.83596 13.9343C9.68555 
                13.8148 10.4996 13.5144 11.2233 13.0535C11.9469 12.5926 12.5633 11.9818 13.0308 11.2625C13.4983 10.5431 13.8062 
                9.7318 13.9335 8.88334C14.0608 8.03488 14.0047 7.16897 13.7689 6.34404C13.7358 6.24767 13.7227 6.14556 13.7303 
                6.04394C13.7379 5.94233 13.7662 5.84332 13.8133 5.75296C13.8604 5.66259 13.9254 5.58276 14.0043 5.51832C14.0832 
                5.45388 14.1745 5.40618 14.2724 5.37812C14.3704 5.35006 14.4731 5.34223 14.5741 5.35511C14.6752 5.36799 14.7726 
                5.40131 14.8604 5.45304C14.9482 5.50477 15.0246 5.57382 15.0848 5.656C15.1451 5.73818 15.188 5.83176 15.2109 
                5.93104C15.6675 7.52857 15.5808 9.23245 14.9644 10.7754C14.348 12.3183 13.2369 13.613 11.8054 14.4563C10.3738 
                15.2995 8.70279 15.6437 7.05449 15.4348C5.4062 15.2259 3.87388 14.4758 2.69794 13.302C1.52329 12.1264 0.77232 
                10.5939 0.562949 8.94526C0.353577 7.29658 0.697657 5.62506 1.54117 4.19312C2.38468 2.76118 3.67985 1.64991 
                5.22334 1.03378C6.76683 0.417663 8.47123 0.331577 10.0689 0.789044C10.2592 0.844656 10.4197 0.973312 10.5154 
                1.1469C10.611 1.32048 10.6341 1.52488 10.5795 1.71542C10.5249 1.90596 10.3972 2.06716 10.2241 2.16377C10.0511 
                2.26039 9.84678 2.28458 9.65594 2.23104C8.76252 1.97449 7.8217 1.92903 6.9077 2.09824C5.99369 2.26746 5.13151 
                2.64672 4.38914 3.20611C3.64677 3.76551 3.04452 4.48974 2.6299 5.32168C2.21528 6.15361 1.99963 7.07051 1.99994 
                8.00004Z"/>
            <path d="M4.99999 7.99999C5.00005 8.54658 5.14934 9.08278 5.43175 9.55075C5.71417 10.0187 6.11899 10.4007 6.60256 
                10.6555C7.08613 10.9103 7.63008 11.0283 8.17576 10.9966C8.72143 10.965 9.24811 10.785 9.69899 10.476C10.1498 
                10.1662 10.5073 9.73912 10.7329 9.24085C10.9585 8.74258 11.0436 8.19212 10.979 7.64899C10.9615 7.51719 10.9795 
                7.38311 11.031 7.26056C11.0826 7.13802 11.1659 7.03143 11.2723 6.95177C11.3788 6.87211 11.5045 6.82226 11.6367 
                6.80736C11.7688 6.79246 11.9025 6.81304 12.024 6.86699C12.1455 6.92023 12.2507 7.00474 12.3289 7.1119C12.407 
                7.21906 12.4554 7.34504 12.469 7.47699C12.5776 8.40509 12.3947 9.34399 11.9455 10.1634C11.4963 10.9828 10.8032 
                11.642 9.96233 12.0496C9.12147 12.4572 8.17458 12.5929 7.25308 12.4379C6.33159 12.2829 5.4812 11.8449 4.81995 
                11.1846C4.1587 10.5244 3.71939 9.67468 3.56299 8.75342C3.40659 7.83216 3.54087 6.88507 3.94718 6.04358C4.35348 
                5.2021 5.01167 4.50798 5.83038 4.05755C6.6491 3.60712 7.58773 3.42274 8.51599 3.52999C8.61538 3.53921 8.71194 
                3.56818 8.79999 3.6152C8.88805 3.66222 8.96583 3.72635 9.02878 3.80382C9.09172 3.88129 9.13857 3.97055 9.16658 
                4.06636C9.19458 4.16218 9.20318 4.26262 9.19186 4.3618C9.18055 4.46097 9.14955 4.5569 9.10068 4.64394C9.05181 
                4.73098 8.98606 4.80739 8.90728 4.86869C8.8285 4.93 8.73827 4.97495 8.64189 5.00093C8.54551 5.02691 8.44491 
                5.03339 8.34599 5.01999C7.92565 4.97118 7.49973 5.01185 7.09623 5.13933C6.69272 5.26681 6.32075 5.47822 6.00474 
                5.75966C5.68873 6.0411 5.43584 6.38621 5.26267 6.77233C5.08951 7.15844 4.99999 7.57682 4.99999 7.99999Z"/>
        </svg>


    )
}
