// FormWaitList.tsx

import React, { useState, useRef, useEffect } from 'react';

import '../../styles/size.css'
import '../../styles/color.css'
import '../../styles/FormWaitList.css'

import { useLangContext } from '../../contexts/LangContext';
import Arrow from '../Utilities/Arrow';

import { postEmail } from '../../helpers/addEmail'

import { LangData } from '../Utilities/Props';


function ConfirmButton() {

    return(
        <button id="ConfirmButton" type="submit">
            <Arrow id="ArrowConfirmButton"/>
        </button>
    );
}

interface FormWaitListProps {
    setCode: (code: number) => void;
    data: {[key: string]: LangData};
}

export default function FormWaitList({setCode, data}: FormWaitListProps) {
    const lang = useLangContext().lang;
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [email, setEmail] = useState('');

    const addEmail = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const promise = postEmail(email);

        promise.then((res) => {
            setEmail('');
            setCode(1);
            if (res.data.code === 2){
                setEmail('');
                setCode(2);
            } else {
                setEmail('');
                setCode(1);
            }
        }).catch(err => {
            console.log(err);
            setCode(3);
        });
    }

    useEffect(() => {
        const handleClick = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
        };

        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.addEventListener('click', handleClick);
            inputElement.addEventListener('blur', handleBlur);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('click', handleClick);
                inputElement.removeEventListener('blur', handleBlur);
            }
        };
    }, []);

    return (
        <div className='input-container'>
            <span className={`permanent-placeholder ${email != '' ? 'slideUp' : undefined}`}>
                {data['placeholder'][lang]}
            </span>

            <form id="FormWaitList" onSubmit={addEmail} className={isFocused ? 'form-focused' : ''}>
                <input type="email" 
                    ref={inputRef}
                    required={true}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder={data["placeholder"][lang]}
                    id="Form"/>

                <ConfirmButton/>
            </form>

            <span className="legal">
                {data['legal'][lang]}
            </span>
        </div>
    );
}
