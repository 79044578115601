import React from "react";
import { useMediaQuery } from "react-responsive";

import "../../../styles/Graph.css";

interface LinkProps {
    advancement?: number;
    space?: number;
    spaceAroundPoints?: number;
    end?: boolean;
}

export default function Link({advancement = 0, space = 0.05, spaceAroundPoints = 0.03, end}: LinkProps) {
    const startWhite = advancement > 0 ? advancement + spaceAroundPoints : space;
    const endGreen = advancement < 1 ? advancement - spaceAroundPoints : 1 - space;

    const isDesktop = useMediaQuery({ query: "(min-width : 800px), (orientation : landscape)" });

    return (
        <svg className="end link">
            <marker
                id="arrow"
                viewBox="0 0 10 10"
                refX="9"
                refY="5"
                markerWidth="6"
                markerHeight="6"
                orient="auto-start-reverse"
            >
                <path d="M 0 0 L 10 5 L 0 10" fill="none" stroke="white"/>
            </marker>


            {advancement > 0 && 
                <line 
                    className="link-line green" 
                    x1={isDesktop ? `${space * 100}%` : "50%"}
                    x2={isDesktop ? `${endGreen * 100}%` : "50%"}
                    y1={isDesktop ? "50%" : `${space * 100}%`}
                    y2={isDesktop ? "50%" : `${endGreen * 100}%`}
                />
            }

            {advancement > 0 && advancement < 1 &&
                <circle 
                    className="graph-link-ball" 
                    cx={isDesktop ? `${advancement * 100}%` : "50%"}
                    cy={isDesktop ? "50%" : `${advancement * 100}%`}
                    r="5"
                    fill="white"/>
            }

            {advancement < 1 &&
                <line
                    className="link-line white"
                    x1={isDesktop ? `${startWhite * 100}%` : "50%"}
                    x2={isDesktop ? `${(1 - space) * 100}%` : "50%"}
                    y1={isDesktop ? "50%" : `${startWhite * 100}%`}
                    y2={isDesktop ? "50%" : `${(1 - space) * 100}%`}
                    markerEnd={end? "url(#arrow)" : undefined}
                />
            }
        </svg>
    );
}
