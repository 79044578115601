// SparkAbstract.tsx

import React from 'react';

import Buttton from './Button';

import { LangData } from '../Utilities/Props';
import { useLangContext } from '../../contexts/LangContext';


interface SparkAbstractProps {
    data: {
        description: LangData;
        button: LangData;
        image: {
            url: string;
            alt: LangData;
        };
    };
}

export default function SparkAbstract({ data }: SparkAbstractProps) {
    const lang = useLangContext().lang;

    function handleLoad() {
        console.log('Image loaded');
    } 

    return(
        <div className='spark_abstract'>
            <div className='description'>
                <p>
                    {data['description'][lang]}
                </p>
                <Buttton id="spark_button" to="Spark" data={data['button']}/>
            </div>

            <img src={data['image']['url']} alt={data['image']['alt'][lang]} onLoad={handleLoad}/>
        </div>
    )
}
