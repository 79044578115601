// Ads.tsx

import React from 'react';

import '../../styles/Ads.css';
import '../../styles/size.css';
import '../../styles/color.css';

import Page from '../Utilities/Page';
import ContactButton from '../Utilities/ContactButton';
import NextClients from './NextClients';
import PersoReco from './PersoReco';
import JoinUs from './JoinUs';

import all_data from '../../data.json';
import Graph from '../Utilities/Graph/Graph';


export default function Ads() {

    const data = all_data["Ads"];

    return(
        <Page 
            id="Ads" 
            title="ALL Ads"
            canonical="htttps://all-enterprises.com/Ads"
            appleTouchIcon="/ALL_favicon/apple-touch-icon.png"
            icon_32="/ALL_favicon/favicon-32x32.png"
            icon_16="/ALL_favicon/favicon-16x16.png"
            manifest='/ALL_favicon/site.webmanifest'
        >
            <div id='contact-button-header'>
                <ContactButton/>
            </div>
            
            <NextClients ads={data["title"]} data={data["nextClients"]}/>
            <PersoReco {...data["persoReco"]}/>
            <JoinUs {...data["joinUs"]}/>

            <Graph data={data["road-map"]}/>

        </Page>
    )
}
