// Slider.tsx

import React, { useEffect, useState } from "react";

import Card, { CardData } from "./Card";

import "../../styles/Spark.css"

interface IndicatorProps {
    active: number;
    nb: number;
}

function Indicator({active, nb} : IndicatorProps) {
    function weight(advancement: number, index: number) {
        return Math.exp(-(20 * ((index / (nb - 1))- advancement) ** 2));
    }

    const bubbles = Array.from({length: nb}, (_, i) => (
        <div key={i}
            style={{width: `calc(${weight(active, i)} * 10 * var(--scale))`}}/>
    ));

    return (
        <div className="indicator">
            {bubbles}
        </div>
    )
}


interface SliderProps {
    data: CardData[];
}

export default function Slider({ data } : SliderProps) {
    const ref = React.useRef<HTMLDivElement>(null);
    const [scrollWidth, setScrollWidth] = useState(0);

    useEffect(() => {
        function handleScroll() {
            if (!ref.current) {
                return;
            }
            setScrollWidth(ref.current.scrollLeft / (ref.current.scrollWidth - ref.current.clientWidth));
        }

        ref.current?.addEventListener('scroll', handleScroll);

        return () => {
            ref.current?.removeEventListener('scroll', handleScroll);
        }
    });


    const cards = Object.values(data).map((cardData, index) => (
        <Card key={index} data={cardData}/>
    ));


    return (
        <div className="slider">
            <div className="cards" ref={ref}>
                {cards}
            </div>

            <Indicator active={scrollWidth} nb={cards.length}/>
            
        </div>
    )
}
