// JoinUs.tsx

import React from 'react';

import '../../styles/Ads.css';
import '../../styles/size.css';
import '../../styles/color.css';

import { useLangContext } from '../../contexts/LangContext';

import ContactButton from '../Utilities/ContactButton';
import JoinUsLogo from '../Utilities/JoinUsLogo';
import { BlocProps } from '../Utilities/Props';

export default function JoinUs(data : BlocProps) {
    const lang = useLangContext().lang;

    return(
        <section id="JoinUs">
            <div className="container">
                <h2>
                    {data.title[lang]}
                </h2>

                <p>
                    {data.description[lang]}
                </p>

                <ContactButton/>
            </div>

            <JoinUsLogo/>

        </section>
    );
}