import React from "react";

import "../../styles/Spark.css"
import { BlocProps } from "../Utilities/Props";
import { useLangContext } from "../../contexts/LangContext";


interface CardData extends BlocProps {
    bg?: string;
}

interface CardProps {
    data: CardData;
    key?: number;
}

export default function Card({data, key} : CardProps) {
    const lang = useLangContext().lang;

    const {title, subtitle, description, bg} = data;

    return (
        <div
            className="card"
            style={bg ? {
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            } : undefined}
            key={key}
        >
            <div className="text">
                <h2>{title[lang]}</h2>
                <h3>{subtitle? subtitle[lang] : null}</h3>
                <p>{description[lang]}</p>
            </div>
        </div>
    )
}

export type { CardData };
