// Cross.tsx

import React from "react";


export default function Cross() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="plus" style={{ transform: 'rotate(45deg)', transformOrigin: 'center' }}>
            <path d="M18 12.998h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 0 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2" />
        </svg>
    );
}