// PubPresentation.tsx

import React, { useRef, useEffect } from "react";

import '../../styles/PubPresentation.css';
import '../../styles/size.css';
import '../../styles/color.css';

import { useScrollContext } from '../../contexts/ScrollContext';

export default function PubPresentation() {
    const parentRef = useRef<SVGSVGElement | null>(null);
    const pubRef = useRef<SVGGElement | null>(null);

    const scroll = useScrollContext().scroll;

    useEffect(() => {
        if (pubRef.current && parentRef.current) {
            if (scroll === null) {
                pubRef.current.style.transform = `translateY(0px)`;
            } else {
                pubRef.current.style.transform = `translateY(${(-scroll+0.2)*0.3*parentRef.current.getBoundingClientRect()['height']}px)`;
            }
        }
    }, [scroll]);

    return(
        <svg viewBox="0 0 343 651" fill="none" xmlns="http://www.w3.org/2000/svg" id='pubPresentation' ref={parentRef}>
            <defs>
                <pattern id="imgDark" width="100%" height="100%">
                    <image href="/assets/pawel-czerwinski-NTYYL9Eb9y8-unsplash.jpg" x="0" y="0" width="100%"/>
                </pattern>
            </defs>

            <defs>
                <pattern id="imgLight" width="100%" height="100%">
                    <image href="/assets/pawel-czerwinski-Tyg0rVhOTrE-unsplash.jpg" x="0" y="0" width="100%"/>
                </pattern>
            </defs>

            <rect x="37" y="75.2573" width="270" height="500" rx="30"/>

            <path d="M136 110H208" strokeLinecap="round"/>
            
            <g ref={pubRef}>
                <rect x="13.5" y="327.5" width="317" height="117" rx="34" id="rectPub"/>
                <circle cx="287" cy="413" r="20"/>
                <path d="M274 423 H299 L286.5 398 L274 423 Z" fill="#FF0000"/>
            </g>
        </svg>
    )
}