import React from "react";

import Page from "../Utilities/Page";

import "../../styles/Spark.css";
import "../../styles/color.css";
import "../../styles/size.css";

import Banner from "./Banner";
import Slider from "./Slider";

import data from "../../data.json";


export default function Spark() {
    const cardsData = data["Spark"]["Cards"];
    const bannerData = data["Spark"]["banner"];

    return (
        <Page title="Spark" resetScroll={true}>
            <Banner data={bannerData}/>

            <Slider data={cardsData}/>
        </Page>
    );
}