import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/index.css';
import './styles/size.css';

import App from './components/App';
import { RootContextProvider } from './contexts/RootContext';

const rootElement = document.getElementById('root');

if (!rootElement) {
    throw new Error("No root element found");
}

const root = ReactDOM.createRoot(rootElement);

root.render(
    <RootContextProvider rootElement={rootElement}>
        <App/>
    </RootContextProvider>
);
