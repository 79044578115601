import React from 'react';

import '../../styles/Footer.css'
import '../../styles/size.css'
import '../../styles/color.css'


interface SectionProps {
    title: string;
    children: React.ReactNode;
}


export default function Section({title, children} : SectionProps) {
    return(
        <div className='section-footer'>
            <h2>{title}</h2>
            {children}
        </div>
    )
}
