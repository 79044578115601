import React, { useState } from 'react';

import '../../styles/Tune.css'
import '../../styles/size.css'
import '../../styles/color.css'

import Page from '../Utilities/Page';
import Graph from '../Utilities/Graph/Graph';
import FormWaitList from './FormWaitList';
import TuneDescription from './TuneDescription';
import Popup from './Popup';

import all_data from '../../data.json';
import SparkAbstract from './SparkAbstract';


export default function Tune() {
    const [ registred, setCode ] = useState(0);

    const data = all_data["Tune"];

    return (
        <Page 
            title='Tune'
            canonical="https://all-enterprises.com/"
            appleTouchIcon='/Tune_favicon/apple-touch-icon.png'
            icon_32='/Tune_favicon/favicon-32x32.png'
            icon_16='/Tune_favicon/favicon-16x16.png'
            favicon='/Tune_favicon/favicon.ico'
            manifest='/Tune_favicon/site.webmanifest'
        >
            <Popup registred={registred} setCode={setCode} messages={data["enrollment"]}/>
            <TuneDescription description={data["description"]} value={data['value']}/>
            <FormWaitList setCode={setCode} data={data["newsletter"]}/>

            <div className='bento'>
                <SparkAbstract data={data["spark"]}/>

                <Graph data={data["road-map"]}/>
            </div>
        </Page>
    );
}
