// LinkedInLogo.tsx

import React from 'react'

import '../../styles/ALLButton.css'
import '../../styles/size.css'
import '../../styles/color.css'


export default function LinkedInLogo() {
    return(
        <svg viewBox="0 0 51 51" id="LinkedInLogo">
            <rect width="50" height="50" transform="translate(0.5 0.0144043)"/>
            <path d="M38.6979 38.2207H33.1458V29.5186C33.1458 27.4436 33.1042 24.7727 30.25 24.7727C27.3562 
                24.7727 26.9146 27.0311 26.9146 29.3665V38.2207H21.3604V20.3269H26.6937V22.7644H26.7667C27.5125 
                21.3602 29.325 19.8748 32.0333 19.8748C37.6583 19.8748 38.7 23.579 38.7 
                28.3977V38.2207H38.6979ZM15.0896 17.879C14.6657 17.8795 14.246 17.7964 13.8543 17.6344C13.4626 
                17.4724 13.1068 17.2347 12.8072 16.9349C12.5076 16.6351 12.2701 16.2791 12.1084 
                15.8873C11.9466 15.4956 11.8638 15.0757 11.8646 14.6519C11.865 14.0141 12.0545 13.3907 12.4093 
                12.8605C12.764 12.3304 13.2679 11.9174 13.8574 11.6737C14.4468 11.43 15.0953 11.3665 15.7208 
                11.4914C16.3463 11.6162 16.9207 11.9237 17.3715 12.375C17.8222 12.8264 18.129 13.4012 18.253 
                14.0269C18.3771 14.6525 18.3128 15.3009 18.0683 15.8901C17.8238 16.4792 17.4101 16.9826 
                16.8796 17.3366C16.349 17.6907 15.7274 17.8794 15.0896 17.879ZM17.8729 
                38.2207H12.3042V20.3269H17.875V38.2207H17.8729ZM41.4792 6.2644H9.51875C7.98542 6.2644 6.75 
                7.47274 6.75 8.96649V41.0623C6.75 42.5561 7.9875 43.7644 9.51667 43.7644H41.4708C43 43.7644 
                44.25 42.5561 44.25 41.0623V8.96649C44.25 7.47274 43 6.2644 41.4708 6.2644H41.4792Z" 
                className='bgTextBloc'/>
        </svg>
    )
}
